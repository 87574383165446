<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 产品详情</div>
            <div class="flex" v-if="form" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">产品编号:</div>
                        <div class="flex1">{{form.product_no}}</div>
                    </div>
                    <div class="flex item">
                        <div class="tt">品名:</div>
                        <div class="flex1">
                            <div v-for="(item,index) in form.product_name" :key="index">
                                {{item.product_name}} (助记码 {{item.mnemonic_code}})
                            </div>
                        </div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">规格:</div>
                        <div class="flex1">{{form.spec_value_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">型号:</div>
                        <div class="flex1">{{form.model}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">产品注册证名称:</div>
                        <div class="flex1">{{form.register_cert_name}}</div>
                    </div>
                    <div class="flex item">
                        <div class="tt">注册证号:</div>
                        <div class="flex1">
                            <div v-for="(item,index) in form.product_register_cert" :key="index">
                                {{item.register_cert_no}} (到期时间 {{item.register_cert_expire}})
                            </div>
                        </div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">包装单位:</div>
                        <div class="flex1">{{form.pack_unit_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">生产厂家:</div>
                        <div class="flex1">{{form.manufacturer}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">产地:</div>
                        <div class="flex1">{{form.origin}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">类别:</div>
                        <div class="flex1">{{form.type_name}}</div>
                    </div>

                    <div class="flex_ac item">
                        <div class="tt">产品证件:</div>
                        <div class="flex1 flex">
                            <div class="picture-card-img" v-for="(item,index) in imgs" :key="index">
                                <img :src="$http+item.url" class="picture-card-imgs" alt="">
                                <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                                        class="el-icon-zoom-in"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">备注:</div>
                        <div class="flex1">{{form.remark}}</div>
                    </div>
                </div>

                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">产品分类:</div>
                        <div class="flex1">{{form.category_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">储存条件:</div>
                        <div class="flex1">{{form.storage_condition_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">进价:</div>
                        <div class="flex1">{{form.purchase_price}} 元</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">零售价:</div>
                        <div class="flex1">{{form.price}} 元</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">库存上限:</div>
                        <div class="flex1">{{form.stock_upper}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">库存下限:</div>
                        <div class="flex1">{{form.stock_lower}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">Code编码:</div>
                        <div class="flex1">{{form.coding}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">组件编码:</div>
                        <div class="flex1">{{form.module_coding}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">医保编码:</div>
                        <div class="flex1">{{form.medical_insurance_coding}}</div>
                    </div>
                    <div class="flex item">
                        <div class="tt">自定义编码:</div>
                        <div class="flex1">
                            <div v-for="(item,index) in form.product_customize_coding" :key="index">
                                {{item.customize_coding}}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>

        </el-card>
        <!-- 上传图片预览 -->
        <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
            <img style="width: 100%;" :src="uploadImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "productDetail",
        data() {
            return {
                form: '',
                imgs: [],

                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/product.Product/read", {
                    product_id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        let img = [];
                        let ids = [];
                        for (let i = 0; i < _data.product_image.length; i++) {
                            img.push({
                                id: _data.product_image[i].file_id,
                                url: '/storage/' + _data.product_image[i].save_path.split('/storage/')[
                                    1]
                            });
                            ids.push(_data.product_image[i].file_id)
                        }
                        this.imgs = img;
                        this.form = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 50%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 120px
    }

    .avatar {
        height: 80px;
    }
</style>
